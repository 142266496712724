import { createFlowCard } from "@deliverr/ui-facility/lib-facility/flow/createFlowCard";
import { ScanFlowCardType } from "crossdock/common/flow/ScanFlowCardType";

export const createPrintInboundPalletLabelCard = createFlowCard(ScanFlowCardType.INBOUNDS_PRINT_PALLET_LABELS);
export const createPrintInboundPalletLabelAsnCard = createFlowCard(ScanFlowCardType.INBOUNDS_PRINT_PALLET_LABELS_ASN);

export const createScanInboundPalletCard = createFlowCard(ScanFlowCardType.FREIGHT_MOVE_PALLET_SCAN_INBOUND_PALLET);
export const createScanStagingLaneCard = createFlowCard(ScanFlowCardType.FREIGHT_MOVE_PALLET_SCAN_STAGING_LANE);

export const createStartProcessingPalletCard = createFlowCard(ScanFlowCardType.FREIGHT_START_PROCESSING_PALLET);
