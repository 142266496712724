import styled from "@emotion/styled";
import React from "react";
import { usePrintPalletLabelAsn } from "./usePrintPalletLabelAsn";
import { useIntl } from "react-intl";
import labels from "./PrintPalletLabelAsnCard.labels";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { LoadingButton } from "@deliverr/ui-facility";
import { FACILITY_THEME } from "@deliverr/ui-facility/lib-facility/theme";

const NextButton = styled(LoadingButton)`
  margin-top: ${FACILITY_THEME.spacing.S2};
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

export const PrintPalletLabelAsnCard: React.FC = () => {
  const { saveAsnIdAndTransitionToPalletCountCard, updateAsnId, asnId, loading, inputErrorMessage } =
    usePrintPalletLabelAsn();
  const { formatMessage } = useIntl();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await saveAsnIdAndTransitionToPalletCountCard(asnId);
  };

  return (
    <form onSubmit={onSubmit}>
      <TextInputCard
        title={formatMessage(labels.title)}
        message={formatMessage(labels.message)}
        value={asnId}
        icon="document"
        accentColor="BLUE"
        placeholder={formatMessage(labels.asnIdPlaceholder)}
        onChange={updateAsnId}
        errorMessage={inputErrorMessage}
        disabled={loading}
      />
      <NextButton onClick={saveAsnIdAndTransitionToPalletCountCard} disabled={false}>
        <span>{formatMessage(labels.nextButton)}</span>
      </NextButton>
    </form>
  );
};
