import { useRecoilState } from "recoil";
import { useIntl } from "react-intl";
import { useLoading } from "crossdock/common/useLoading";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { genericOnScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { createPrintInboundPalletLabelCard } from "../InboundPalletLabelCardCreators";
import { asnIdState } from "./inboundPalletState";
import labels from "./PrintPalletLabelAsnCard.labels";

export const usePrintPalletLabelAsn = () => {
  const [asnId, setAsnId] = useRecoilState(asnIdState);
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const { loading, loadWhilePending } = useLoading();
  const { addFlowCard } = useScanFlow();
  const { formatMessage } = useIntl();

  const updateAsnId = (newAsnId: string) => {
    if (newAsnId) {
      if (Number.isNaN(Number(newAsnId))) {
        inputError(formatMessage(labels.asnIdNotANumberError));
        return;
      }

      if (Number(newAsnId) <= 0) {
        inputError(formatMessage(labels.asnIdZeroError));
        return;
      }
    }

    setAsnId(newAsnId);
  };

  const saveAsnIdAndTransitionToPalletCountCard = loadWhilePending(
    resetErrorOnExecution(async (newAsnId) => {
      if (!newAsnId) {
        inputError(formatMessage(labels.asnIdRequiredError));
        return;
      }

      addFlowCard(createPrintInboundPalletLabelCard({}));
    })
  );

  return {
    inputErrorMessage,
    updateAsnId: genericOnScannerInputChange(asnId, updateAsnId, saveAsnIdAndTransitionToPalletCountCard, "upper"),
    asnId,
    saveAsnIdAndTransitionToPalletCountCard,
    loading,
  };
};
