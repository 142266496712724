export enum ScanFlowCardType {
  CASE_UNRECEIVED = "CASE_UNRECEIVED",

  SCANNER_SCAN = "SCANNER_SCAN",
  SORT_BY_BOX_BREAK = "SORT_BY_BOX_BREAK",
  SORT_BY_LABEL = "SORT_BY_LABEL",
  SORT_BY_WAREHOUSE = "SORT_BY_WAREHOUSE",
  SORT_PALLET_TO_MILK_RUN = "SORT_PALLET_TO_MILK_RUN",
  SORT_PALLET_TO_MILK_RUN_ERROR = "SORT_PALLET_TO_MILK_RUN_ERROR",
  INVALID_PALLET_INPUT = "INVALID_PALLET_INPUT",

  ADD_CASE_SCAN_CASE = "ADD_CASE_SCAN_CASE",
  ADD_CASE_SCAN_CASE_SUCCESS = "ADD_CASE_SCAN_CASE_SUCCESS",
  ADD_CASE_HAS_PALLET = "ADD_CASE_HAS_PALLET",
  ADD_CASE_SCAN_PALLET = "ADD_CASE_SCAN_PALLET",
  ADD_CASE_SCAN_PALLET_SUCCESS = "ADD_CASE_SCAN_PALLET_SUCCESS",
  ADD_CASE_CLOSED_PALLET_ERROR = "ADD_CASE_CLOSED_PALLET_ERROR",

  PALLET_NO_BOXES_ERROR = "PALLET_NO_BOXES_ERROR",

  SHIP_PALLET_SCAN_PALLET = "SHIP_PALLET_SCAN_PALLET",
  SHIP_PALLET_SCAN_PALLET_SUCCESS = "SHIP_PALLET_SCAN_PALLET_SUCCESS",
  SHIP_PALLET_HAS_TRACKING = "SHIP_PALLET_HAS_TRACKING",
  SHIP_PALLET_SCAN_TRACKING = "SHIP_PALLET_SCAN_TRACKING",
  SHIP_PALLET_SCAN_TRACKING_SUCCESS = "SHIP_PALLET_SCAN_TRACKING_SUCCESS",
  SHIP_PALLET_TRACKING_IN_USE = "SHIP_PALLET_TRACKING_IN_USE",

  CLOSE_PALLET_SCAN_PALLET = "CLOSE_PALLET_SCAN_PALLET",
  CLOSE_PALLET_SCAN_PALLET_SUCCESS = "CLOSE_PALLET_SCAN_PALLET_SUCCESS",
  CLOSE_PALLET_INVALID_STATUS_ERROR = "CLOSE_PALLET_INVALID_STATUS_ERROR",

  REOPEN_PALLET_SCAN_PALLET = "REOPEN_PALLET_SCAN_PALLET",
  REOPEN_PALLET_SCAN_PALLET_SUCCESS = "REOPEN_PALLET_SCAN_PALLET_SUCCESS",
  REOPEN_PALLET_INVALID_STATUS_ERROR = "REOPEN_PALLET_INVALID_STATUS_ERROR",

  PALLET_TRACKING_SCAN_ERROR = "PALLET_TRACKING_SCAN_ERROR",

  MISSING_LABEL_SCAN_PO = "MISSING_LABEL_SCAN_PO",
  MISSING_LABEL_SCAN_TRACKING = "MISSING_LABEL_SCAN_TRACKING",
  MISSING_LABEL_OPEN_CASE = "MISSING_LABEL_OPEN_CASE",
  MISSING_LABEL_BOX_CONTENTS = "MISSING_LABEL_BOX_CONTENTS",
  MISSING_LABEL_REVIEW_SKUS = "MISSING_LABEL_REVIEW_SKUS",

  BOX_AUDIT_SCAN_BOX = "BOX_AUDIT_SCAN_BOX",
  BOX_AUDIT_OPEN_CASE = "BOX_AUDIT_OPEN_CASE",
  BOX_AUDIT_BOX_CONTENTS = "BOX_AUDIT_BOX_CONTENTS",
  BOX_AUDIT_REVIEW_SKUS = "BOX_AUDIT_REVIEW_SKUS",
  BOX_AUDIT_UNKNOWN_BARCODE_BCL_UPLOAD = "BOX_AUDIT_UNKNOWN_BARCODE_BCL_UPLOAD",
  BOX_AUDIT_UNKNOWN_BARCODE_BARCODE_UPLOAD = "BOX_AUDIT_UNKNOWN_BARCODE_BARCODE_UPLOAD",
  BOX_AUDIT_UNKNOWN_BARCODE_PRODUCT_UPLOAD = "BOX_AUDIT_UNKNOWN_BARCODE_PRODUCT_UPLOAD",

  DAMAGED_PRODUCT = "DAMAGED_PRODUCT",

  BOX_BREAK_CREATE_CONTAINERS_AMOUNT = "BOX_BREAK_CREATE_CONTAINERS_AMOUNT",
  BOX_BREAK_LOGIN = "BOX_BREAK_LOGIN",
  BOX_BREAK_LINK_CONTAINER_SCAN_CONTAINER = "BOX_BREAK_LINK_CONTAINER_SCAN_CONTAINER",
  BOX_BREAK_LINK_CONTAINER_SCAN_CHUTE = "BOX_BREAK_LINK_CONTAINER_SCAN_CHUTE",
  BOX_BREAK_LINK_CONTAINER_SUCCESS = "BOX_BREAK_LINK_CONTAINER_SUCCESS",
  BOX_BREAK_SCAN_BOX = "BOX_BREAK_SCAN_BOX",
  BOX_BREAK_SCAN_BOX_SUCCESS = "BOX_BREAK_SCAN_BOX_SUCCESS",
  BOX_BREAK_SCAN_ANY_UNIT = "BOX_BREAK_SCAN_ANY_UNIT",
  BOX_BREAK_SCAN_SAME_UNIT = "BOX_BREAK_SCAN_SAME_UNIT",
  BOX_BREAK_UNIT_IN_CHUTE = "BOX_BREAK_UNIT_IN_CHUTE",
  BOX_BREAK_RETURN_UNIT = "BOX_BREAK_RETURN_UNIT",
  BOX_BREAK_VERIFY_CHUTE = "BOX_BREAK_VERIFY_CHUTE",
  BOX_BREAK_REMOVE_CONTAINER = "BOX_BREAK_REMOVE_CONTAINER",
  BOX_BREAK_REMOVE_CONTAINER_SUCCESS = "BOX_BREAK_REMOVE_CONTAINER_SUCCESS",
  BOX_BREAK_END_EARLY_CARD = "BOX_BREAK_END_EARLY_CARD",
  BOX_BREAK_COUNT_UNEXPECTED_SKU_UNITS = "BOX_BREAK_COUNT_UNEXPECTED_SKU_UNITS",
  BOX_BREAK_BULK_TRANSFER = "BOX_BREAK_BULK_TRANSFER",

  FREIGHT_ARRIVAL_SCAN_INBOUND_PALLET_LABEL = "FREIGHT_ARRIVAL_SCAN_INBOUND_PALLET_LABEL",
  FREIGHT_ARRIVAL_SCAN_PALLET = "FREIGHT_ARRIVAL_SCAN_PALLET",
  FREIGHT_ARRIVAL_ENTER_PO_NUM = "FREIGHT_ARRIVAL_ENTER_PO_NUM",
  FREIGHT_ARRIVAL_MOVE_PALLET_NOW = "FREIGHT_ARRIVAL_MOVE_PALLET_NOW",
  FREIGHT_ARRIVAL_SCAN_LANE = "FREIGHT_ARRIVAL_SCAN_LANE",
  FREIGHT_ARRIVAL_SCAN_TRANSFER = "FREIGHT_ARRIVAL_SCAN_TRANSFER",
  FREIGHT_ARRIVAL_HIGH_PRIORITY_PALLET = "FREIGHT_ARRIVAL_HIGH_PRIORITY_PALLET",
  FREIGHT_ARRIVAL_SEND_TO_FULFILLMENT = "FREIGHT_ARRIVAL_SEND_TO_FULFILLMENT",

  INBOUNDS_PRINT_PALLET_LABELS = "INBOUNDS_PRINT_PALLET_LABELS",
  INBOUNDS_PRINT_PALLET_LABELS_ASN = "INBOUNDS_PRINT_PALLET_LABELS_ASN",
  INBOUNDS_SCAN_PALLET = "INBOUNDS_SCAN_PALLET",
  FREIGHT_MOVE_PALLET_SCAN_INBOUND_PALLET = "FREIGHT_MOVE_PALLET_SCAN_INBOUND_PALLET",
  FREIGHT_MOVE_PALLET_SCAN_STAGING_LANE = "FREIGHT_MOVE_PALLET_SCAN_STAGING_LANE",
  FREIGHT_START_PROCESSING_PALLET = "FREIGHT_START_PROCESSING_PALLET",

  FC_DIMS_AND_WEIGHT_SCAN_PRODUCT_BARCODE = "FC_DIMS_AND_WEIGHT_SCAN_PRODUCT_BARCODE",
  FC_DIMS_AND_WEIGHT_SCAN_PRODUCT_DIMS = "FC_DIMS_AND_WEIGHT_SCAN_PRODUCT_DIMS",
  FC_DIMS_AND_WEIGHT_CAPTURE_CUBISCAN_IMAGES = "FC_DIMS_AND_WEIGHT_CAPTURE_CUBISCAN_IMAGES",

  CROSSDOCK_DIMS_AND_WEIGHT_SCAN_PACKAGE_CDSKU = "CROSSDOCK_DIMS_AND_WEIGHT_SCAN_PACKAGE_CDSKU",
  CROSSDOCK_DIMS_AND_WEIGHT_SCAN_PRODUCT_BARCODE = "CROSSDOCK_DIMS_AND_WEIGHT_SCAN_PRODUCT_BARCODE",
  CROSSDOCK_DIMS_AND_WEIGHT_SCAN_PRODUCT_DIMS = "CROSSDOCK_DIMS_AND_WEIGHT_SCAN_PRODUCT_DIMS",
  CROSSDOCK_DIMS_AND_WEIGHT_CAPTURE_CUBISCAN_IMAGES = "CROSSDOCK_DIMS_AND_WEIGHT_CAPTURE_CUBISCAN_IMAGES",
}
