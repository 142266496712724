import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "crossdock.printInboundPalletLabelsAsnAsn.title",
    defaultMessage: "Enter PO Number",
  },

  asnIdPlaceholder: {
    id: "crossdock.printInboundPalletLabelsAsnAsn.asnIdPlaceholder",
    defaultMessage: "PO Number / ASN ID",
  },

  asnIdRequiredError: {
    id: "crossdock.printInboundPalletLabelsAsnAsn.asnIdRequiredError",
    defaultMessage: "ASN ID is required.",
  },

  asnIdNotANumberError: {
    id: "crossdock.printInboundPalletLabelsAsnAsn.asnIdNotANumberError",
    defaultMessage: "ASN ID must be a number.",
  },

  asnIdZeroError: {
    id: "crossdock.printInboundPalletLabelsAsnAsn.asnIdZeroError",
    defaultMessage: "ASN ID must be greater than zero.",
  },

  message: {
    id: "crossdock.printInboundPalletLabelsAsnAsn.message",
    defaultMessage: "Enter the Flexport PO Number or ASN ID that is provided on the shipment.",
  },

  nextButton: {
    id: "crossdock.printInboundPalletLabelsAsnAsn.nextButton",
    defaultMessage: "Next",
  },
});
