import { CaseUnreceivedCard } from "../cards";
import {
  ScannerScanCard,
  SortByBoxBreakCard,
  SortByWarehouseCard,
  SortPalletToMilkRunCard,
  SortPalletToMilkRunErrorCard,
} from "crossdock/modes/caseScanner/cards";
import {
  AddCaseHasPalletCard,
  AddCaseScanCaseCard,
  AddCaseScanCaseSuccessCard,
  AddCaseScanPalletCard,
  AddCaseScanPalletSuccessCard,
} from "crossdock/modes/transfers/AddCaseToPallet";
import {
  ShipPalletHasTrackingCard,
  ShipPalletScanPalletCard,
  ShipPalletScanPalletSuccessCard,
  ShipPalletScanTrackingCard,
  ShipPalletScanTrackingInUseCard,
  ShipPalletScanTrackingSuccessCard,
} from "crossdock/modes/transfers/ShipPallet";
import {
  MissingLabelBoxContentsCard,
  MissingLabelReviewSkusCard,
  MissingLabelScanBoxPoCard,
  MissingLabelScanTrackingCard,
} from "crossdock/modes/problem/MissingLabel/cards";
import { DamagedProductCard } from "crossdock/modes/problem/CrossdockDamagedProduct";
import { ScanFlowCardType } from "../ScanFlowCardType";
import { FlowCardMap } from "@deliverr/ui-facility/lib-facility/flow/types/FlowMaps";
import { AddCaseToClosedPalletErrorCard } from "crossdock/modes/transfers/AddCaseToPallet/AddCaseToClosedPalletErrorCard";
import { ClosePalletScanPalletCard, ClosePalletScanPalletSuccessCard } from "crossdock/modes/transfers/ClosePallet";
import { ClosePalletInvalidStatusCard } from "crossdock/modes/transfers/ClosePallet/ClosePalletAlreadyClosedErrorCard";
import { InvalidPalletInputCard } from "crossdock/modes/transfers/InvalidPalletInputCard";
import {
  ReopenPalletInvalidStatusCard,
  ReopenPalletScanPalletCard,
  ReopenPalletScanPalletSuccessCard,
} from "crossdock/modes/transfers/ReopenPallet";
import {
  BoxBreakCountUnexpectedSkuUnitsCard,
  BoxBreakEndEarlyCard,
  BoxBreakLoginCard,
  BoxBreakReturnUnitCard,
  BoxBreakScanAnyUnitCard,
  BoxBreakScanBoxCard,
  BoxBreakScanBoxSuccessCard,
  BoxBreakUnitInChuteCard,
  BoxBreakVerifyChuteCard,
  BulkTransferCard,
  CreateContainersAmountCard,
  LinkContainerScanChuteCard,
  LinkContainerScanContainerCard,
  LinkContainerSuccessCard,
  RemoveContainerCard,
  RemoveContainerSuccessCard,
} from "crossdock/modes/break/boxBreakCards";
import {
  FreightArrivalEnterPoNumCard,
  FreightArrivalHighPriorityPalletCard,
  FreightArrivalMovePalletNowCard,
  FreightArrivalScanInboundPalletLabelCard,
  FreightArrivalScanLaneCard,
  FreightArrivalScanPalletCard,
  FreightArrivalScanTransferCard,
  FreightArrivalSendToFulfillmentCard,
} from "crossdock/modes/transfers/FreightArrival/cards";
import { BoxAuditScanBoxCard } from "crossdock/modes/problem/BoxAudit/BoxAuditScanBox";
import { BoxAuditBoxContentsCard } from "crossdock/modes/problem/BoxAudit/BoxAuditBoxContents";
import { OpenCaseCard } from "crossdock/modes/problem/components/OpenCaseCard";
import { PalletNoBoxesErrorCard } from "crossdock/modes/transfers/common/PalletNoBoxesErrorCard/PalletNoBoxesErrorCard";
import { BoxAuditUnknownBarcodeSkuBarcodeUploadCard } from "crossdock/modes/problem/BoxAudit/BoxAuditUnknownBarcode/BoxAuditUnknownBarcodeSkuBarcodeUploadCard";
import { BoxAuditUnknownBarcodeBclUploadCard } from "crossdock/modes/problem/BoxAudit/BoxAuditUnknownBarcode/BoxAuditUnknownBarcodeBclUploadCard";
import { BoxAuditUnknownBarcodeProductUploadCard } from "crossdock/modes/problem/BoxAudit/BoxAuditUnknownBarcode/BoxAuditUnknownBarcodeProductUploadCard";
import { ReviewSkusCard } from "@deliverr/ui-facility";
import { PalletTrackingScanErrorCard } from "crossdock/modes/transfers/common/PalletTrackingScanErrorCard";
import { PrintPalletLabelCard, PrintPalletLabelAsnCard } from "../../../modes/transfers/InboundPalletLabels";
import {
  MovePalletScanInboundPalletCard,
  MovePalletScanStagingLaneCard,
} from "crossdock/modes/transfers/InboundPalletLabels/MovePallet";
import { StartProcessingPalletCard } from "crossdock/modes/transfers/InboundPalletLabels/StartProcessingPallet";
import { FcDimsAndWeightScanProductBarcodeCard } from "crossdock/modes/inspection/fc-dims-and-weight/cards/ScanProductBarcodeCard/FcDimsAndWeightScanProductBarcodeCard";
import { FcDimsAndWeightProductDimsCard } from "crossdock/modes/inspection/fc-dims-and-weight/cards/ScanProductDimsCard/FcDimsAndWeightScanProductDimsCard";
import { CrossdockDimsAndWeightScanPackageCdskuCard } from "crossdock/modes/inspection/crossdock-dims-and-weight/cards/ScanPackageCdskuCard/CrossdockDimsAndWeightScanPackageCdskuCard";
import { CrossdockDimsAndWeightScanProductBarcodeCard } from "crossdock/modes/inspection/crossdock-dims-and-weight/cards/ScanProductBarcode/CrossdockDimsAndWeightScanProductBarcodeCard";
import { CrossdockDimsAndWeightScanProductDimsCard } from "crossdock/modes/inspection/crossdock-dims-and-weight/cards/ScanProductDims/CrossdockDimsAndWeightScanProductDimsCard";
import { FcDimsAndWeightCaptureCubiscanImagesCard } from "crossdock/modes/inspection/fc-dims-and-weight/cards/CaptureCubiscanImagesCard/FcDimsAndWeightCaptureCubiscanImagesCard";
import { CrossdockDimsAndWeightCaptureCubiscanImagesCard } from "crossdock/modes/inspection/crossdock-dims-and-weight/cards/CaptureCubiscanImagesCard/CrossdockDimsAndWeightCaptureCubiscanImagesCard";

export const scanFlowCardMap: FlowCardMap = {
  [ScanFlowCardType.CASE_UNRECEIVED]: CaseUnreceivedCard,

  [ScanFlowCardType.SCANNER_SCAN]: ScannerScanCard,
  [ScanFlowCardType.SORT_BY_BOX_BREAK]: SortByBoxBreakCard,
  [ScanFlowCardType.SORT_BY_WAREHOUSE]: SortByWarehouseCard,
  [ScanFlowCardType.SORT_PALLET_TO_MILK_RUN]: SortPalletToMilkRunCard,
  [ScanFlowCardType.SORT_PALLET_TO_MILK_RUN_ERROR]: SortPalletToMilkRunErrorCard,
  [ScanFlowCardType.INVALID_PALLET_INPUT]: InvalidPalletInputCard,

  [ScanFlowCardType.ADD_CASE_SCAN_CASE]: AddCaseScanCaseCard,
  [ScanFlowCardType.ADD_CASE_SCAN_CASE_SUCCESS]: AddCaseScanCaseSuccessCard,
  [ScanFlowCardType.ADD_CASE_HAS_PALLET]: AddCaseHasPalletCard,
  [ScanFlowCardType.ADD_CASE_SCAN_PALLET]: AddCaseScanPalletCard,
  [ScanFlowCardType.ADD_CASE_SCAN_PALLET_SUCCESS]: AddCaseScanPalletSuccessCard,
  [ScanFlowCardType.ADD_CASE_CLOSED_PALLET_ERROR]: AddCaseToClosedPalletErrorCard,

  [ScanFlowCardType.SHIP_PALLET_SCAN_PALLET]: ShipPalletScanPalletCard,
  [ScanFlowCardType.SHIP_PALLET_SCAN_PALLET_SUCCESS]: ShipPalletScanPalletSuccessCard,
  [ScanFlowCardType.SHIP_PALLET_HAS_TRACKING]: ShipPalletHasTrackingCard,
  [ScanFlowCardType.SHIP_PALLET_SCAN_TRACKING]: ShipPalletScanTrackingCard,
  [ScanFlowCardType.SHIP_PALLET_SCAN_TRACKING_SUCCESS]: ShipPalletScanTrackingSuccessCard,
  [ScanFlowCardType.SHIP_PALLET_TRACKING_IN_USE]: ShipPalletScanTrackingInUseCard,

  [ScanFlowCardType.CLOSE_PALLET_SCAN_PALLET]: ClosePalletScanPalletCard,
  [ScanFlowCardType.CLOSE_PALLET_SCAN_PALLET_SUCCESS]: ClosePalletScanPalletSuccessCard,
  [ScanFlowCardType.CLOSE_PALLET_INVALID_STATUS_ERROR]: ClosePalletInvalidStatusCard,
  [ScanFlowCardType.PALLET_NO_BOXES_ERROR]: PalletNoBoxesErrorCard,

  [ScanFlowCardType.REOPEN_PALLET_SCAN_PALLET]: ReopenPalletScanPalletCard,
  [ScanFlowCardType.REOPEN_PALLET_SCAN_PALLET_SUCCESS]: ReopenPalletScanPalletSuccessCard,
  [ScanFlowCardType.REOPEN_PALLET_INVALID_STATUS_ERROR]: ReopenPalletInvalidStatusCard,

  [ScanFlowCardType.PALLET_TRACKING_SCAN_ERROR]: PalletTrackingScanErrorCard,

  [ScanFlowCardType.MISSING_LABEL_OPEN_CASE]: OpenCaseCard,
  [ScanFlowCardType.MISSING_LABEL_SCAN_PO]: MissingLabelScanBoxPoCard,
  [ScanFlowCardType.MISSING_LABEL_SCAN_TRACKING]: MissingLabelScanTrackingCard,
  [ScanFlowCardType.MISSING_LABEL_REVIEW_SKUS]: MissingLabelReviewSkusCard,
  [ScanFlowCardType.MISSING_LABEL_BOX_CONTENTS]: MissingLabelBoxContentsCard,

  [ScanFlowCardType.BOX_AUDIT_SCAN_BOX]: BoxAuditScanBoxCard,
  [ScanFlowCardType.BOX_AUDIT_OPEN_CASE]: OpenCaseCard,
  [ScanFlowCardType.BOX_AUDIT_BOX_CONTENTS]: BoxAuditBoxContentsCard,
  [ScanFlowCardType.BOX_AUDIT_REVIEW_SKUS]: ReviewSkusCard,
  [ScanFlowCardType.BOX_AUDIT_UNKNOWN_BARCODE_BCL_UPLOAD]: BoxAuditUnknownBarcodeBclUploadCard,
  [ScanFlowCardType.BOX_AUDIT_UNKNOWN_BARCODE_BARCODE_UPLOAD]: BoxAuditUnknownBarcodeSkuBarcodeUploadCard,
  [ScanFlowCardType.BOX_AUDIT_UNKNOWN_BARCODE_PRODUCT_UPLOAD]: BoxAuditUnknownBarcodeProductUploadCard,

  [ScanFlowCardType.DAMAGED_PRODUCT]: DamagedProductCard,

  [ScanFlowCardType.BOX_BREAK_CREATE_CONTAINERS_AMOUNT]: CreateContainersAmountCard,
  [ScanFlowCardType.BOX_BREAK_LOGIN]: BoxBreakLoginCard,
  [ScanFlowCardType.BOX_BREAK_LINK_CONTAINER_SCAN_CHUTE]: LinkContainerScanChuteCard,
  [ScanFlowCardType.BOX_BREAK_LINK_CONTAINER_SCAN_CONTAINER]: LinkContainerScanContainerCard,
  [ScanFlowCardType.BOX_BREAK_LINK_CONTAINER_SUCCESS]: LinkContainerSuccessCard,
  [ScanFlowCardType.BOX_BREAK_SCAN_BOX]: BoxBreakScanBoxCard,
  [ScanFlowCardType.BOX_BREAK_SCAN_BOX_SUCCESS]: BoxBreakScanBoxSuccessCard,
  [ScanFlowCardType.BOX_BREAK_SCAN_ANY_UNIT]: BoxBreakScanAnyUnitCard,
  [ScanFlowCardType.BOX_BREAK_RETURN_UNIT]: BoxBreakReturnUnitCard,
  [ScanFlowCardType.BOX_BREAK_UNIT_IN_CHUTE]: BoxBreakUnitInChuteCard,
  [ScanFlowCardType.BOX_BREAK_VERIFY_CHUTE]: BoxBreakVerifyChuteCard,
  [ScanFlowCardType.BOX_BREAK_REMOVE_CONTAINER]: RemoveContainerCard,
  [ScanFlowCardType.BOX_BREAK_REMOVE_CONTAINER_SUCCESS]: RemoveContainerSuccessCard,
  [ScanFlowCardType.BOX_BREAK_END_EARLY_CARD]: BoxBreakEndEarlyCard,
  [ScanFlowCardType.BOX_BREAK_COUNT_UNEXPECTED_SKU_UNITS]: BoxBreakCountUnexpectedSkuUnitsCard,
  [ScanFlowCardType.BOX_BREAK_BULK_TRANSFER]: BulkTransferCard,

  [ScanFlowCardType.FREIGHT_ARRIVAL_SCAN_INBOUND_PALLET_LABEL]: FreightArrivalScanInboundPalletLabelCard,
  [ScanFlowCardType.FREIGHT_ARRIVAL_SCAN_PALLET]: FreightArrivalScanPalletCard,
  [ScanFlowCardType.FREIGHT_ARRIVAL_ENTER_PO_NUM]: FreightArrivalEnterPoNumCard,
  [ScanFlowCardType.FREIGHT_ARRIVAL_MOVE_PALLET_NOW]: FreightArrivalMovePalletNowCard,
  [ScanFlowCardType.FREIGHT_ARRIVAL_SCAN_LANE]: FreightArrivalScanLaneCard,
  [ScanFlowCardType.FREIGHT_ARRIVAL_SCAN_TRANSFER]: FreightArrivalScanTransferCard,
  [ScanFlowCardType.FREIGHT_ARRIVAL_HIGH_PRIORITY_PALLET]: FreightArrivalHighPriorityPalletCard,
  [ScanFlowCardType.FREIGHT_ARRIVAL_SEND_TO_FULFILLMENT]: FreightArrivalSendToFulfillmentCard,

  [ScanFlowCardType.INBOUNDS_PRINT_PALLET_LABELS]: PrintPalletLabelCard,
  [ScanFlowCardType.INBOUNDS_PRINT_PALLET_LABELS_ASN]: PrintPalletLabelAsnCard,
  [ScanFlowCardType.FREIGHT_MOVE_PALLET_SCAN_INBOUND_PALLET]: MovePalletScanInboundPalletCard,
  [ScanFlowCardType.FREIGHT_MOVE_PALLET_SCAN_STAGING_LANE]: MovePalletScanStagingLaneCard,
  [ScanFlowCardType.FREIGHT_START_PROCESSING_PALLET]: StartProcessingPalletCard,

  [ScanFlowCardType.FC_DIMS_AND_WEIGHT_SCAN_PRODUCT_BARCODE]: FcDimsAndWeightScanProductBarcodeCard,
  [ScanFlowCardType.FC_DIMS_AND_WEIGHT_SCAN_PRODUCT_DIMS]: FcDimsAndWeightProductDimsCard,
  [ScanFlowCardType.FC_DIMS_AND_WEIGHT_CAPTURE_CUBISCAN_IMAGES]: FcDimsAndWeightCaptureCubiscanImagesCard,

  [ScanFlowCardType.CROSSDOCK_DIMS_AND_WEIGHT_SCAN_PACKAGE_CDSKU]: CrossdockDimsAndWeightScanPackageCdskuCard,
  [ScanFlowCardType.CROSSDOCK_DIMS_AND_WEIGHT_SCAN_PRODUCT_BARCODE]: CrossdockDimsAndWeightScanProductBarcodeCard,
  [ScanFlowCardType.CROSSDOCK_DIMS_AND_WEIGHT_SCAN_PRODUCT_DIMS]: CrossdockDimsAndWeightScanProductDimsCard,
  [ScanFlowCardType.CROSSDOCK_DIMS_AND_WEIGHT_CAPTURE_CUBISCAN_IMAGES]: CrossdockDimsAndWeightCaptureCubiscanImagesCard,
};
